<template>
	<div class="news">
		<div class="headers">
			<div v-for="(item,index) in list" :key='index' :class="filg==index?'actvlist':''" class="helist" @click="switeh(index)">{{item.name}}</div>
		</div>
		<div style="background-color: #fff;padding-bottom: 30px;margin-top: 8px;">
			<div class="content" v-if="filg==0">
				<el-table :data="tableData.records" border style="width: 100%">
					<el-table-column prop="title" label="消息标题" width="180" align="center"></el-table-column>
					<el-table-column prop="content" label="消息内容" width="300" align="center"></el-table-column>
					<el-table-column prop="createTime" label="发送时间" align="center"></el-table-column>
					<el-table-column label="审核内容" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.authMsg">{{scope.row.authMsg}}</div>
							<video v-else :src='scope.row.contentImg' style="width: 108px;height: 60px;"></video>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div v-else class="reply">
				<div v-for="(item,index) in tableData.records" :key='index' class="replist">
					<div class="rep_left">
						<img :src="item.headImg" class="rep_img" v-if='item.headImg' />
						<img src="@/assets/img/user.png" class="rep_img" v-else />
						<div style="font-size: 14px;color: #535353;max-width: 700px;">
							<div class="rep_nikname">
								<span>{{item.nickName}}</span>
								<span v-if="item.doType==0">回复了我的评论</span>
								<span v-else-if="item.doType==1">评论了我的帖子</span>
								<span v-else>赞了我的帖子</span>
							</div>
							<div class="rep_conten">{{item.content}}</div>
							<div class="rep_time">
								<span>{{item.createTime}}</span>
								<div class="rep_huif" @click="replys(item)" v-if="item.doType!==2">
									<img src="../../../assets/img/xie/huifu.png" />
									<span>回复</span>
								</div>
							</div>
						</div>
					</div>
					<div class="rep_right">
						<div v-if="item.authMsg">{{item.authMsg}}</div>
						<video v-else :src='item.contentVideo' style="width: 108px;height: 60px;"></video>
					</div>
				</div>
				<div v-if="tableData.records.length==0" style="text-align: center;color: #909399;">暂无数据</div>
			</div>
			<div class="pagings">
				<el-pagination background layout="prev, pager, next" 
				:page-size='parsm.pageSize' 
				@current-change="CurrentChange" 
				:total="tableData.total">
				</el-pagination>
			</div>
		</div>
		<div class="dilogs">
			<el-dialog :title="titles" :visible.sync="dialogVisible" width="500px">
			  <div>
					<el-input v-model="commen.content" type='textarea' maxlength="500" :rows='5' show-word-limit placeholder="请输入内容"></el-input>
				</div>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisible = false">取消</el-button>
			    <el-button type="primary" @click="subtem">确认</el-button>
			  </span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { getnews } from "@/request/xie";
	import { postforumReply,getreads } from "@/request/homeApi";
	export default{
		data(){
			return{
				list:[
					{name:'审核通知'},
					{name:'回复我的'}
				],
				filg:0,
				tableData:[],
				dialogVisible:false,
				parsm:{
					pageNumber:1,
					pageSize:10,
					type:0,
					sort:'createTime',
					memberId:''
				},
				titles:'',
				commen:{
					pid:'',
					content:'',
					forumId:''
				}
			}
		},
		mounted() {
			let user=JSON.parse(localStorage.getItem('user'))
			this.parsm.memberId=user.id
			this.getlist()
			if(this.$route.query.code>0){
				this.read()
			}
		},
		methods:{
			//消息已读
			async read(){
				let res=await getreads(1)
				console.log(res)
			},
			//获取审核消息
			async getlist(){
				let res= await getnews(this.parsm)
				this.tableData=res.result
				//console.log(res)
			},
			//页数改变时
			CurrentChange(page){
				this.parsm.pageNumber = page;
				this.getlist();
			},
			//切换
			switeh(ind){
				this.filg=ind
				if(this.filg==0){
					this.parsm.type=0
				}else{
					this.parsm.type=1
				}
				this.parsm.pageNumber=1
				this.getlist()
			},
			//回复
			replys(item){
				this.titles=`回复 ${item.nickName}`
				this.commen.pid=item.remarks
				this.commen.forumId=item.forumId
				this.commen.content=''
				this.dialogVisible=true
			},
			//提交回复
			subtem(){
				if(this.commen.content==''){
					this.$message.error('请填写回复内容')
					return false
				}
				postforumReply(this.commen).then((res)=>{
					if(res.code==200){
						this.$message.success('回复成功')
						this.dialogVisible=false
						this.getlist()
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.news{
		padding-bottom: 20px;
		.headers{
			height: 63px;
			display: flex;
			background-color: #fff;
			padding: 0 50px 0 40px;
			line-height: 60px;
			.helist{
				color: #818181;
				font-size: 16px;
				margin-right: 20px;
				cursor: pointer;
			}
			.actvlist{
				color: #00A3E0;
				border-bottom: 2px solid #00A3E0;
			}
		}
		.content{
			padding: 23px 37px 20px 38px;
			box-sizing: border-box;
		}
		.reply{
			padding: 16px 37px 20px 38px;
			.replist{
				margin-top: 18px;
				display: flex;
				flex-flow: row;
				justify-content: space-between;
				padding-bottom: 20px;
				border-bottom: 1px solid #F3F3F3;
				.rep_left{
					width: 45%;
					display: flex;
					.rep_img{
						width: 50px;
						height: 50px;
						border-radius: 50%;
						margin-right: 14px;
					}
					.rep_nikname{
						margin-bottom: 5px;
						span{
							color: #818181;
						}
						span:first-child{
							color: #535353;
							font-weight: bold;
							display: inline-block;
							margin-right: 5px;
						}
					}
					.rep_conten{
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}
					.rep_time{
						display: flex;
						align-items: center;
						margin-top: 10px;
						color: #818181;
						font-size: 12px;
						.rep_huif{
							cursor: pointer;
							display: flex;
							align-items: center;
							margin-left: 25px;
							img{
								width: 12px;
								height: 12px;
								margin-right: 5px;
							}
						}
					}
				}
				.rep_right{
					width: 45%;
					font-size: 14px;
					color: #535353;
					display: flex;
					flex-flow: row;
					justify-content: flex-end;
					div{
						width: 100%;
						text-align: right;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 4;
					}
				}
			}
		}
		.pagings{
			padding-right: 27px;
			display: flex;
			flex-flow: row;
			justify-content: flex-end;
		}
		::v-deep .dilogs{
			.el-dialog__header{
				padding: 0 0 0 20px;
				height: 50px;
				line-height: 50px;
				box-sizing: border-box;
				border-radius: 4px 4px 0px 0px;
				font-size: 16px;
				color: #535353;
				background-color: #F8F9FB;
				button{
					display: none;
				}
			}
			.el-dialog__body{
				padding: 22px 25px 35px 27px;
				box-sizing: border-box;
				font-size: 16px;
				color: #535353;
			}
			.el-dialog__footer{
				padding-top: 0;
			}
			.dialog-footer{
				display: inline-block;
				width: 100%;
				display: flex;
				flex-flow: row;
				justify-content: center;
				button{
					width: 100px;
					padding: 12px 0;
					text-align: center;
				}
				button:first-child{
					background-color: #97999B;
					color: #fff;
				}
			}
		}
	}
</style>
